/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { IoCalendarSharp as CalendarIcon } from 'react-icons/io5';
import { GiMoneyStack as Money } from 'react-icons/gi';
import { IoMdPerson as Person } from 'react-icons/io';

export const i18 = {
  pl: {
    header: 'Formularz rejestracji',
    type: 'Rodzaj zajęć',
    option1: 'Warsztaty',
    option2: 'Milongi',
    name1: 'Imię osoby biorącej udział',
    surname1: 'Nazwisko osoby biorącej udział',
    name2: 'Imię drugiej osoby biorącej udział',
    surname2: 'Nazwisko drugiej osoby biorącej udział',
    email: 'Adres email',
    button: 'Wyślij',
    msg: 'Formularz został poprawnie wysłany.',
    fail: 'Błąd podczas wysyłania formularza.',
    activity: 'Zajęcia do wyboru',
    tou: 'Rejestrując się akceptuję regulamin festiwalu dostępny tutaj - ',
    rodo: ' Polityka RODO serwisu: ',
    solo: 'Wybór uczestnika na zajęcia solo',
    full: 'Zapisy zakończone',
  },
  eng: {
    header: 'Registration form',
    type: 'Type of activity',
    option1: 'Workshops',
    option2: 'Milongas',
    name1: "First participant's name",
    surname1: "First participant's surname",
    name2: "Second participant's name",
    surname2: "Second participant's surname",
    email: 'Email address',
    button: 'Send',
    msg: 'Form has been succesfully submitted.',
    fail: 'Error during form submission.',
    activity: 'Choose activities you want to attend to',
    tou: 'By registering I accept festival terms and conditions available here - ',
    rodo: 'Website GDPR rules: ',
    solo: 'Participant for the solo activity',
    full: 'Registrations closed',
  },
};

const RegistrationForm = ({ language, setLoading, products }) => {
  const [type, setType] = useState('workshops');
  const [name1, setName1] = useState('');
  const [name2, setName2] = useState('');
  const [surname1, setSurname1] = useState('');
  const [surname2, setSurname2] = useState('');
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState();
  const [chosenEntries, setChosenEntries] = useState([]);
  const [combinedEntries, setCombinedEntries] = useState();
  const [formType, setFormType] = useState();
  const [soloPerson, setSoloPerson] = useState(undefined);

  const [submitForm] = useMutation(REGISTRATION_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      resetState();
      setIsSuccess(res.createFestivalRegistration.success);
    },
  });

  const handleEntryChange = (entry) => {
    const entries = [...chosenEntries];
    const index = entries.findIndex((e) => e.forms_data.namePl === entry.forms_data.namePl);
    if (index > -1) {
      entries.splice(index, 1);
    } else {
      entries.push(entry);
    }
    if (entries.length > 0) {
      let combined = [];
      entries.forEach((e) => {
        let text = '';
        text +=
          language === 'pl'
            ? `${e.forms_data.namePl}, cena: ${e.forms_data.pricePl}.`
            : `${e.forms_data.nameEng}, price: ${e.forms_data.priceEng}}.`;
        combined.push(text);
      });
      combined = combined.join(`\n`);
      setCombinedEntries(combined);
    }
    let numberOfSolo = 0;
    let numberOfDuo = 0;
    entries.forEach((e) => {
      if (e.forms_data.isSolo === 'para') {
        numberOfDuo += 1;
      } else {
        numberOfSolo += 1;
      }
    });
    if (numberOfDuo > 0 && numberOfSolo > 0) {
      setFormType('mixed');
    } else if (numberOfDuo > 0 && numberOfSolo === 0) {
      setFormType('duo');
    } else if (numberOfDuo === 0 && numberOfSolo > 0) {
      setFormType('solo');
    } else {
      setFormType(undefined);
    }
    setChosenEntries(entries);
  };

  const renderDate = (date) => {
    const formattedDateArray = date.toLocaleString().split('.');
    const formattedDate = [formattedDateArray[1], formattedDateArray[0], formattedDateArray[2]].join('.');
    return formattedDate;
  };

  // TODO: Isolate this into a separate component
  const renderOptions = () =>
    products &&
    products
      .filter((p) => p.forms_data.type.toLocaleLowerCase() === type.toLocaleLowerCase())
      .map((p) => (
        <div className="form-data">
          <label>
            <span className="form-data__name">
              {language === 'pl' ? p.forms_data.namePl : p.forms_data.nameEng}
              {p.forms_data.isFull && `. ${i18[language].full}`}
            </span>
            <span className="form-data__pair">
              <Person />
              {p.forms_data.isSolo === 'para' ? <Person /> : ''}
            </span>
            <span className="form-data__price">
              <Money />
              {language === 'pl' ? p.forms_data.pricePl : p.forms_data.priceEng}
            </span>
            <span className="form-data__date">
              <CalendarIcon />
              {renderDate(new Date(p.forms_data.startingDate))}
              {' - '}
              {new Date(p.forms_data.endDate).toLocaleTimeString()}
            </span>
          </label>
          <label className={`container ${p.forms_data.isFull ? 'disabled' : ''}`}>
            <input disabled={p.forms_data.isFull} onChange={() => handleEntryChange(p)} type="checkbox" />
            <span className="checkmark" />
          </label>
        </div>
      ));

  const resetState = () => {
    setEmail('');
    setName1('');
    setName2('');
    setSurname1('');
    setSurname2('');
    setType('workshops');
    setChosenEntries([]);
    setFormType(undefined);
    setSoloPerson(undefined);
    setCombinedEntries();
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const solo = soloPerson || ' ';
    const tempName2 = name2 || ' ';
    const tempSurname2 = surname2 || ' ';
    try {
      await submitForm({
        variables: {
          clientMutationId: `${new Date().toUTCString()}`,
          email,
          name_1: name1,
          surname_1: surname1,
          type,
          name_2: tempName2,
          surname_2: tempSurname2,
          activity: combinedEntries,
          solo_person: solo,
          lang: language,
        },
      });
      setIsSuccess(true);
    } catch (err) {
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const getHeaderValue = () => {
    let header;
    if (isSuccess === undefined && language) {
      header = i18[language].header;
    } else if (language) {
      header = isSuccess ? i18[language].msg : i18[language].fail;
    }
    return header;
  };

  const handleTypeChange = (value) => {
    setType(value);
    const elements = document.getElementsByClassName('form-data');
    const arrOfElements = [].slice.call(elements);
    arrOfElements.forEach((e) => {
      e.lastChild.firstChild.checked = false;
    });
    setChosenEntries([]);
    setCombinedEntries();
  };

  return (
    <>
      <h1 className="animate-left">{getHeaderValue()}</h1>
      <form onSubmit={(e) => handleFormSubmit(e)} className="animate-left">
        <label>{i18[language].type}</label>
        <select onChange={(e) => handleTypeChange(e.target.value)} value={type} required>
          <option value="workshops">{i18[language].option1}</option>
          <option value="milonga">{i18[language].option2}</option>
        </select>
        <label>{i18[language].activity}</label>
        {renderOptions()}
        <label>{i18[language].name1}</label>
        <input value={name1} onChange={(e) => setName1(e.target.value)} type="text" required />
        <label>{i18[language].surname1}</label>
        <input value={surname1} onChange={(e) => setSurname1(e.target.value)} type="text" required />
        {formType !== 'solo' && (
          <>
            <label>{i18[language].name2}</label>
            <input value={name2} onChange={(e) => setName2(e.target.value)} type="text" required />
            <label>{i18[language].surname2}</label>
            <input value={surname2} onChange={(e) => setSurname2(e.target.value)} type="text" required />
          </>
        )}
        <label>{i18[language].email}</label>
        <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" required />
        {formType === 'mixed' && name1.length > 3 && name2.length > 3 && (
          <>
            <label>{i18[language].solo}</label>
            <select value={soloPerson} onChange={(e) => setSoloPerson(e.target.value)} required>
              <option value={`${name1} ${surname1}`}>{`${name1} ${surname1}`}</option>
              <option value={`${name2} ${surname2}`}>{`${name2} ${surname2}`}</option>
            </select>
          </>
        )}
        <div className="form-data">
          <label>
            {i18[language].tou}
            <a
              rel="noreferrer"
              target="_blank"
              href={language === 'pl' ? '/REGULAMIN_DDT_2021.odt' : '/RULES_REGULATIONS2021.docx'}
            >
              link
            </a>
            .
            <br />
            {i18[language].rodo}
            <a rel="noreferrer" target="_blank" href={language === 'pl' ? '/RODO_PL.docx' : '/RODO_ENDDT-2021.docx'}>
              link
            </a>
            .
          </label>
          <label className="container">
            <input required type="checkbox" />
            <span className="checkmark" />
          </label>
        </div>
        <button disabled={chosenEntries.length < 1} type="submit">
          {i18[language].button}
        </button>
      </form>
    </>
  );
};

export default RegistrationForm;

const REGISTRATION_MUTATION = gql`
  mutation CreateRegistration(
    $clientMutationId: String!
    $type: String!
    $name_1: String!
    $surname_1: String!
    $email: String!
    $name_2: String
    $surname_2: String
    $activity: String
    $solo_person: String
    $lang: String
  ) {
    createFestivalRegistration(
      input: {
        clientMutationId: $clientMutationId
        email: $email
        name_1: $name_1
        surname_1: $surname_1
        type: $type
        name_2: $name_2
        surname_2: $surname_2
        activity: $activity
        solo_person: $solo_person
        lang: $lang
      }
    ) {
      success
      data
    }
  }
`;
